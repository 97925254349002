@charset "UTF-8";
/* @import 'bootstrap.min.css'; */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
body{font-family: 'Open Sans', 'Source Sans Pro', sans-serif;line-height: initial;}

.footerHoveredText:hover {
  color: white !important;
}
.inputSearch{
  border:none !important;
}
.inputSearch:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.searchButton{
  border:none !important;
}
.searchButton:hover {
  background-color: #343a40;
}
.socNetwork li a i:hover {
  color: #5cb85c !important;
}
.socNetwork li {
  float: left;
  margin-left: 2rem;
}
.hoverWarningButton:hover {
  color: #343a40;
}
.dropdown-toggle::after {
  margin-left: 1.3em !important;
}
.categorycards h3 a {
  text-decoration: none;
  color: aliceblue;
}
.categorycards h3 a:hover {
  text-decoration: underline;
  color: #5bc0de;
}
/* flip category image by hover */
.flip-container {
  perspective: 1000;
  display: inline-block;
  text-decoration: none;
  color: #ebebeb;
}
.flip-container:hover {
  color: #5cb85c;
}
.flip-container:hover .cardflip {
  transform: rotateY(180deg);
}
.cardflip, .frontflip, .backflip {
  width: 70px;
  height: 70px;
  background: transparent;
  border: none;
}
.cardflip {
  transition: 0.5s;
  transform-style: preserve-3d;
  position: relative;
}
.frontflip, .backflip {
  backface-visibility: hidden;
  position: absolute;
}
.frontflip {
  z-index: 1;
  transform: rotateY(180deg);
}
.backflip {
  z-index: 2;
  transform: rotateY(0deg);
}
.backflip img {
  max-width: 70px;
}
.flip-container h5 {
  font-size: 0.9rem;
  max-width: 5em;
}
/* END flip category image by hover */
.cat-header-link:hover {
  color: #5bc0de !important;
  text-decoration: underline !important;
}
.rating {
  font-size: 0.92rem;
  font-weight: 500;
}
.rating span {
  font-size: 0.9rem !important;
  margin-left: 0.1rem;
}
.rating a:hover {
  color: #6d5200 !important;
}
#navbarColor01 a:hover {
  color: #20374c !important;
}
/* Slide menu `Categories` in header */
.rootcats{
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  visibility: hidden;
  font-size: 1rem;
  color: #ebebeb;
  background-color: #4e5d6c;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  height: 0;
  transition: .2s ease-in-out;
}
.precats{
  width: 80%;
  left: 10%;
}
.cats {
  display: flex;
  justify-content: center;
  height: inherit;
}
.cats a {
  width: auto;
  clear: inherit;
  text-align: center;
  display: block;
}
.cats span {
  display: flex;
  justify-content: center;
  margin-top: 0.15rem;
}
.cats span img {
  width: 80%;
  float: left;
  height: auto;
}
.slidecats {
  visibility: visible !important;
  height: calc(100vw / 7.2);
}
.presubmenu{
  transform: translateY(-0.3rem);position: fixed !important;
}
.submenu {
  visibility: visible !important;padding: 0.2em;
}
/* END Slide menu `Categories` in header */
.dropdown-toggle::after{
  width: fit-content;display: block;margin: 0 auto !important;
}
.registerlink a:hover{text-decoration: underline !important; color: var(--bs-success);}
/* Cart */
.cart-trashbtn i:hover{
  color: #5cb85c !important;
}
.info-link:hover{
  text-decoration: underline !important;color: var(--bs-dark);
}
/* END of Cart */
/* Checkout Steps */
.checkoutsteps a {
  text-decoration: none;font-size: 0.93em;
}
.checkoutsteps a:hover {
  text-decoration: underline;color:var(--bs-success);
}
.checkoutsteps div {
  font-size: 0.93em;
}
.checkoutsteps a i,  .checkoutsteps div i{
  margin-right: 0.2rem;font-size: 1.4em !important;
}
/* END Checkout Steps */

/* Filter Items */

.filteritems{
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  background-color: whitesmoke;
  width: 15em;
  transform: translate3d(-5em, 1px, 0px);
}
.rangeinput{
  cursor: pointer;
}
.rangeinput::-moz-range-thumb{
  background-color: var(--bs-secondary);
}
.rangeinput::-webkit-slider-thumb{
  background-color: var(--bs-secondary);
}
.rangeinput::-ms-thumb{
  background-color: var(--bs-secondary);
}
.accordion-button:not(.collapsed){
  border-bottom: none !important;
  color: var(--bs-gray-800);
}
.submitbutton{
  letter-spacing: 0.03em;
}
.submitbutton:hover{
  color: var(--bs-gray-900) !important;
}
.checkinput:hover{
  cursor: pointer;
}
.checkinput:checked{
  background-color: var(--bs-dark);
  border-color: #000000;
}
/* END Filter Items */

/* BRANDS */
.brands a:hover{
  text-decoration: underline !important;
  color: var(--bs-info) !important;
}
/* END Brands */

/* Edit Item Admin Screen */
.edititeminpt input {
  font-size: 0.95rem;
}
.edititeminpt input::placeholder{
  font-size: 0.92rem;;
}
.edititeminpt input[type="file"]{
  cursor: pointer;
}
.edititeminpt input[type="file"]::placeholder {
  font-size: 0.5rem !important;
}
.edititeminpt input:hover:not(:disabled):not([readonly])::file-selector-button{
  background-color: var(--bs-info);
}
.edititeminpt textarea {
  font-size: 0.95rem;
  height: 20em;
}
.edititeminpt input[type="checkbox"] {
  cursor: pointer;
}
/* END Edit Item Admin Screen */

@media only screen and (max-width:1200px) {
  .h1, h1 {font-size: calc(1rem + 1.5vw);}
  .preheader{padding: 0 1rem !important;}
  /* .searchfield{margin-left: 6% !important;} */
}

@media only screen and (max-width:995px) {
  .preheader div, .preheader div img{
    float: none !important;
    /* display: flex !important;
    justify-content: center !important; */
  }
  .preheader div img{
    display: block !important;
    margin: 1rem auto 0 auto !important;
  }
  .preheader h1{
    text-align: center;
  }
  .preheader a {
    text-decoration: none;
  }
  .searchfield{
    margin-right: 3% !important;
    display: flex;
    justify-content: center;
  }
  .socNetwork{
    display: flex;
    justify-content: center;
    margin-right: 3%;
    margin-top: 1.5em;
  }
  .socNetwork ul{
    padding-left: 0%;
  }
  #navbarColor01 ul li{
    margin-bottom: 1rem;
  }
  .ordersblock table{
    font-size: 0.7em !important;
  }
  .reviewblock{
    width: 80% !important;
  }
}

@media only screen and (max-width:750px) {
  .cats a{
    font-size: 0.75rem;
    padding: initial;
  }
  .cats span{
    margin-top: auto;
    width:100% !important
  }
  .precats{
    width: 100%;
    left: 0;
  }
  .slidecats{
    height: 32%;
  }
  .loginblock {
    width: 60% !important;
  }
  .ordersblock {
    margin-top: 2em;
  }
}

@media only screen and (max-width:550px) {
  .filterblock{
    right: 5em !important;
  }
  .catname{
    margin-top: 2em !important;
  }
  .catblock{
    margin-bottom: 1em !important;
  }
  .articletext{
    font-size: 0.9em;
  }
  .reviewblock {
    width: 90% !important;
  }
  .hoverWarningButton{
    top:75% !important;
    left: 35% !important;
    padding: 0.1em 0.15em;
    font-size: 0.8em;
  }
}